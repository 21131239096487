.fade.enter {
  animation: fadeIn .4s both;
}
.fade.exit {
  animation: fadeOut .4s both;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
