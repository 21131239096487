* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}
.App {
  height: 100vh;
  // width: 100vw;
  overflow: hidden;
}
::selection {
  color: #fff;
  background-color: steelblue;
}
body {
  background-color: #fff;
  line-height: normal!important; // 会有滚动条
  // padding-bottom: 182px;
  // padding-top: 40px;
}

.footer {
  height: 192px;
  padding-bottom: 10px;
  border-top: 1px solid #eae4e4;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.input-group {
  height: 140px;
  margin: 5px 0;
  display: flex;
  align-items: flex-end;
  .msg-ipt {
    // width: 70%;
    flex: 1;
    height: 140px;
    border: none;
    background-color: #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    outline: none;
    font-size: 16px;
    margin-left: 5px;
    resize: none;
    &::placeholder {
      color: #e6e6e6;
    }
  }
  .btn-group {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__item {
      border: none;
      padding: 0 15px;
      height: 30px;
      border-radius: 20px;
      outline: none;
      color: #fff;
      cursor: pointer;
      margin: 5px;
      margin-top: 0;
      &.send {
        background-color: #01cafc;
        &:disabled {
          background-color: #b3eefe;
        }
      }
    }
  }
}
.shadow-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, .4);
  .other-options {
    width: 100%;
    height: 300px;
    position: fixed;
    bottom: 0;
    border-top: 1px solid #eae4e4;
    background-color: #fff;
    &__title {
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #eae4e4;
      text-align: center;
      margin-bottom: 5px;
    }
  }
}

@media screen and (min-width: 768px) {
  .msg-list {
    width: 50%;
    display: inline-block;
    height: calc(100vh - 44px);
    border-radius: 0;
    border-right: 1px solid #e5e4e4;
  }
  .footer {
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
}
