.msg-list {
  width: 100%;
  height: calc(100vh - 192px - 40px);
  white-space: pre-wrap;
  word-break: break-all;
  position: relative;
  border-radius: 3px;
  overflow-y: scroll;
  overflow-x: hidden;
  .msg-list__scroll {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__empty {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #e6e6e6;
  }
  &__item {
    padding: 10px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 32px;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #d9d7d7;
    }
    &__text {
      line-height: 26px;
      a:hover {
        text-decoration: underline;
      }
      img {
        width: 16px;
        position: relative;
        top: 3px;
        right: -5px;
        cursor: pointer;
      }
    }
    &__date {
      font-size: 12px;
      position: absolute;
      right: 24px;
      top: 1px;
      color: #c5c5c5;
      transition: opacity .3s;
      opacity: 0;
    }
    &:hover &__date {
      opacity: 1;
    }
    &__delete {
      cursor: pointer;
      position: absolute!important;
      right: 5px;
      opacity: .6;
      transition: opacity .1s;
      // &:hover {
      //   opacity: 1;
      // }
    }
  }
  .clears {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50px;
    text-align: center;
    line-height: 20px;
    color: #ccc;
    right: -3px;
    top: 0;
    transition: all .3s;
    cursor: pointer;
  }
  // &__copy {
    // color: #0080ff;
    // margin-left: 5px;
    // font-size: 12px;
    // cursor: pointer;
  // }
  &__down {
    // @extend .msg-list__copy;
    color: #0080ff;
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
    text-decoration: none;
  }
  &__godown {
    position: fixed;
    top: 20px;
    padding: 8px;
    right: 20px;
    outline: none;
    border: 1px solid transparent;
    color: #fff;
    background: #50504d;
    border-radius: 5px;
    letter-spacing: 2px;
  }

  &__img {
    width: 250px;
    // height: 200px;
    border: 1px solid #3799ac;
    border-radius: 5px;
    font-size: 0; // 解决图片间隙
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
  }

  &__shade {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    overflow-y: auto;
    img {
      width: 100%;
    }
  }
}
.file-size {
  font-style: normal;
  margin-left: 5px;
  color: #aeafa6;
  font-size: 14px;
}

.toast {
  color: #fff;
  width: 30%;
  height: 30%;
  width: 112px;
  height: 110px;
  border-radius: 5px;
  background-color: rgba(0,0,0,.7);
  font-size: 12px;
  position: absolute;
  left: calc(70% / 2);
  top: calc(70% / 2);
  // left: 50%;
  // top: 50%;
  // margin-top: -50px;
  // margin-left: -56px;
  text-align: center;
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: center;
  
}

.my-loading {
  margin-bottom: 3px;
  animation: rotates 1.5s cubic-bezier(0.23, 0.25, 0.55, 0.54) infinite;
}
@keyframes rotates{
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}



// 重置antd-mobile 样式
.am-modal-input input {
  padding-left: 12px;
  &[type="text"]::placeholder {
    padding-left: 0;
  }
}

.am-modal-wrap  .am-modal-button-group-h .am-modal-button {
  font-size: 16px;
}


.msg-list__scroll>.images>.msg-list__item.fade-in {
  animation: slideInUp 500ms both
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
