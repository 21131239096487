.header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: steelblue;
  text-align: center;
  font-size: 20px;
  color: #fff;
  position: relative;
  .online {
    position: absolute;
    right: 8px;
    top: 3px;
    font-size: 12px;
  }
  &-name {
    border-color: transparent transparent #888;
    color: #888;
    width: 118px;
  }
}

.online {
  cursor: pointer;
  user-select: none;
}
